<template>
  <div>
    <div class="row">
      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'announceinfor' }">
        <statistic-card-horizontal :color="color" icon="BellIcon" :statistic-title="`${this.announce.length} รายการ`" :badge="String(this.announce.length)" statistic="ประกาศชุมชน" />
      </b-link>

      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'travelinfor' }">
        <statistic-card-horizontal :color="color" icon="MapIcon" :statistic-title="`${this.travel.length} รายการ`" :badge="String(this.travel.length)" statistic="ข้อมูลท่องเที่ยว" />
      </b-link>

      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'businessinfor' }">
        <statistic-card-horizontal :color="color" icon="BriefcaseIcon" :statistic-title="`${this.business.length} รายการ`" :badge="String(this.business.length)" statistic="ข้อมูลธุรกิจ" />
      </b-link>

      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'serviceinfor' }">
        <statistic-card-horizontal :color="color" icon="BookOpenIcon" :statistic-title="`${this.service.length} รายการ`" :badge="String(this.service.length)" statistic="ข้อมูลบริการ" />
      </b-link>

      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'inform' }">
        <statistic-card-horizontal :color="color" icon="AlertTriangleIcon" :statistic-title="`${this.inform.length} รายการ`" :badge="String(this.inform.length)" statistic="แจ้งเหตุ" />
      </b-link>
      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'maintenance' }">
        <statistic-card-horizontal :color="color" icon="ToolIcon" :statistic-title="`${this.maintenance.length} รายการ`" :badge="String(this.maintenance.length)" statistic="แจ้งซ่อมบำรุง" />
      </b-link>
      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'appeal' }">
        <statistic-card-horizontal :color="color" icon="ArchiveIcon" :statistic-title="`${this.appeal.length} รายการ`" :badge="String(this.appeal.length)" statistic="ร้องเรียน" />
      </b-link>
      <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'historyMeet' }">
        <statistic-card-horizontal :color="color" icon="CalendarIcon" :statistic-title="`${Number(this.meetTingCount)} รายการ`" :badge="String(this.meetTingCount)" statistic="ประวัติการเข้าพบ" />
      </b-link>
      <!-- <b-link class="col-12 col-sm-6 col-md-4 col-lg-3" :to="{ name: 'addMeet' }">
        <statistic-card-horizontal :color="color" icon="CalendarIcon" statistic="สร้างนัดหมาย" />
      </b-link> -->

      <div class="col-12 col-md-4 col-lg-3"></div>
      <div class="col-12 col-md-4 col-lg-3"></div>
      <div class="col-12 col-md-4 col-lg-3"></div>
      <div class="col-12 col-md-4 col-lg-3"></div>
      <div class="col-12 col-md-4 col-lg-3"></div>
    </div>
  </div>
</template>

<script>
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
  import API from '@/connect/config';
  import axios from 'axios';
  import dayjs from 'dayjs';
  export default {
    components: {
      StatisticCardHorizontal,
    },
    data() {
      return {
        color: 'primary',
        //announce
        announce: [],
        travel: [],
        business: [],
        service: [],

        //ticket
        inform: [],
        maintenance: [],
        appeal: [],
        //appointment
        meetTingCount: '',
      };
    },
    async created() {
      this.resData_announce();
      this.resData_ticket();
      this.resData_meeting();
    },
    methods: {
      resData_meeting() {
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        let uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
        axios
          .get(`${API}meeting/${userId}?uId=${uId}`)
          .then((res) => {
            let i = 0;
            res.data.message.result.map((el, index) => {
              if (uId == el.uId) {
                i++;
                this.meetTingCount = i;
              }
            });
          })
          .catch((err) => {
            console.error('failed', err);
          });
      },
      resData_announce() {
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        const { tag } = JSON.parse(localStorage.getItem('profile_visitor'));
        //  console.log(tag);
        const data = [];

        this.$store.dispatch('get_announce_filter', `no_category`).then(async (res) => {
          // tag.forEach((el, index) => {
          //   res.forEach((el2) => {
          //     console.log(el2.tag);
          //     el2.tag.forEach((el3) => {
          //       //    console.log(el);
          //       //   console.log(el3)
          //       if (el3 == el) {
          //         console.log(el2);
          //         data.push(el2);
          //       }
          //     });
          //   });
          // });
          // console.log(res);
          res.forEach((el, index) => {
            if (el.category == 'announce') {
              if (dayjs().isAfter(dayjs(el.showDate))) {
                this.announce.push(el);
              }
            }
            if (el.category == 'travel') {
              this.travel.push(el);
            }
            if (el.category == 'business') {
              this.business.push(el);
            }
            if (el.category == 'service') {
              this.service.push(el);
            }
          });
        });
      },
      resData_ticket() {
        const { userId, uId } = JSON.parse(localStorage.getItem('company_jwt_vis'));

        this.$store.dispatch('getData', `ticket/${userId}?uId=${uId}&_page=1&_limit=100`).then(async (res) => {
          res.message.result.forEach((el, index) => {
            if (el.type == 'inform') {
              this.inform.push(el);
            }
            if (el.type == 'maintenance') {
              this.maintenance.push(el);
            }
            if (el.type == 'appeal') {
              this.appeal.push(el);
            }
          });
        });
      },
    },
  };
</script>

<style></style>
